import { combineReducers } from 'redux';
import userReducer from './userReducer';
import authReducer from './authReducer';
import dpoReducer from './dpoReducer';
import regionReducer from './regionReducer';
import telegramReducer from './telegramReducer';
import botReducer from './botReducer';

const rootReducer = combineReducers({
  users: userReducer,
  auth: authReducer,
  dpo: dpoReducer,
  region: regionReducer,
  telegram: telegramReducer,
  bots: botReducer,
});

export default rootReducer;
