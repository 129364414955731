import { FETCH_KABUPATEN_FAILURE, FETCH_KABUPATEN_SUCCESS, FETCH_KECAMATAN_FAILURE, FETCH_KECAMATAN_SUCCESS, FETCH_KELURAHAN_FAILURE, FETCH_KELURAHAN_SUCCESS, FETCH_PROVINSI_FAILURE, FETCH_PROVINSI_SUCCESS } from '../actions/actionTypes';

const initialState = {
    region: [],
    kabupaten: [],
    kecamatan: [],
    kelurahan: [],
    error: null,
};

const regionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROVINSI_SUCCESS:
            return {
                ...state,
                region: action.payload,
                error: null,
            };
        case FETCH_PROVINSI_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case FETCH_KABUPATEN_SUCCESS:
            return {
                ...state,
                kabupaten: action.payload,
                error: null,
            };
        case FETCH_KABUPATEN_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case FETCH_KECAMATAN_SUCCESS:
            return {
                ...state,
                kecamatan: action.payload,
                error: null,
            };
        case FETCH_KECAMATAN_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case FETCH_KELURAHAN_SUCCESS:
            return {
                ...state,
                kelurahan: action.payload,
                error: null,
            };
        case FETCH_KELURAHAN_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default regionReducer;
