import { ADD_USER, DELETE_USER, UPDATE_USER, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE, FETCH_USER_BY_ID_SUCCESS, FETCH_USER_BY_ID_FAILURE, CLEAR_DATA } from '../actions/actionTypes';

const initialState = {
  users: [],
  msg: null,
  user: null,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        msg: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
      };
    case FETCH_USER_BY_ID_FAILURE:
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    case CLEAR_DATA:
      return {
        ...state,
        msg: null,
        error: null
      };
    default:
      return state;
  }
};

export default userReducer;
