// USER
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_FAILURE = 'FETCH_USER_BY_ID_FAILURE';

// BOT
export const ADD_BOT = 'ADD_BOT';
export const DELETE_BOT = 'DELETE_BOT';
export const UPDATE_BOT = 'UPDATE_BOT';
export const FETCH_BOTS_SUCCESS = 'FETCH_BOTS_SUCCESS';
export const FETCH_BOTS_FAILURE = 'FETCH_BOTS_FAILURE';
export const FETCH_BOT_BY_ID_SUCCESS = 'FETCH_BOT_BY_ID_SUCCESS';
export const FETCH_BOT_BY_ID_FAILURE = 'FETCH_BOT_BY_ID_FAILURE';

// TELEGRAM
export const ADD_BOT_TELEGRAM = 'ADD_BOT_TELEGRAM';
export const DELETE_BOT_TELEGRAM = 'DELETE_BOT_TELEGRAM';
export const FETCH_BOT_TELEGRAM_SUCCESS = 'FETCH_BOT_TELEGRAM_SUCCESS';
export const FETCH_BOT_TELEGRAM_FAILURE = 'FETCH_BOT_TELEGRAM_FAILURE';
export const ADD_GROUP_TELEGRAM = 'ADD_GROUP_TELEGRAM';
export const DELETE_GROUP_TELEGRAM = 'DELETE_GROUP_TELEGRAM';
export const FETCH_GROUP_TELEGRAM_SUCCESS = 'FETCH_GROUP_TELEGRAM_SUCCESS';
export const FETCH_GROUP_TELEGRAM_FAILURE = 'FETCH_GROUP_TELEGRAM_FAILURE';
export const FETCH_CHAT_ID_TELEGRAM_SUCCESS = 'FETCH_CHAT_ID_TELEGRAM_SUCCESS';
export const FETCH_CHAT_ID_TELEGRAM_FAILURE = 'FETCH_CHAT_ID_TELEGRAM_FAILURE';

// AUTH
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// DPO
export const FETCH_DPO_BIODATA_SUCCESS = 'FETCH_DPO_BIODATA_SUCCESS';
export const FETCH_DPO_BIODATA_FAILURE = 'FETCH_DPO_BIODATA_FAILURE';
export const FETCH_DPO_DEMOGRAPHIC_SUCCESS = 'FETCH_DPO_DEMOGRAPHIC_SUCCESS';
export const FETCH_DPO_DEMOGRAPHIC_FAILURE = 'FETCH_DPO_DEMOGRAPHIC_FAILURE';
export const FETCH_DPO_NOPOL_SUCCESS = 'FETCH_DPO_NOPOL_SUCCESS';
export const FETCH_DPO_NOPOL_FAILURE = 'FETCH_DPO_NOPOL_FAILURE';
export const FETCH_DPO_PHONE_SUCCESS = 'FETCH_DPO_PHONE_SUCCESS';
export const FETCH_DPO_PHONE_FAILURE = 'FETCH_DPO_PHONE_FAILURE';
export const FETCH_DPO_PHONE_OWNER_SUCCESS = 'FETCH_DPO_PHONE_OWNER_SUCCESS';
export const FETCH_DPO_PHONE_OWNER_FAILURE = 'FETCH_DPO_PHONE_OWNER_FAILURE';
export const FETCH_DPO_FAMILY_SUCCESS = 'FETCH_DPO_FAMILY_SUCCESS';
export const FETCH_DPO_FAMILY_FAILURE = 'FETCH_DPO_FAMILY_FAILURE';
export const FETCH_DPO_PHOTO_SUCCESS = 'FETCH_DPO_PHOTO_SUCCESS';
export const FETCH_DPO_PHOTO_FAILURE = 'FETCH_DPO_PHOTO_FAILURE';
export const FETCH_DPO_KONSOLIDASI_SUCCESS = 'FETCH_DPO_KONSOLIDASI_SUCCESS';
export const FETCH_DPO_KONSOLIDASI_FAILURE = 'FETCH_DPO_KONSOLIDASI_FAILURE';

// REGION
export const FETCH_PROVINSI_SUCCESS = 'FETCH_PROVINSI_SUCCESS';
export const FETCH_PROVINSI_FAILURE = 'FETCH_PROVINSI_FAILURE';
export const FETCH_KABUPATEN_SUCCESS = 'FETCH_KABUPATEN_SUCCESS';
export const FETCH_KABUPATEN_FAILURE = 'FETCH_KABUPATEN_FAILURE';
export const FETCH_KECAMATAN_SUCCESS = 'FETCH_KECAMATAN_SUCCESS';
export const FETCH_KECAMATAN_FAILURE = 'FETCH_KECAMATAN_FAILURE';
export const FETCH_KELURAHAN_SUCCESS = 'FETCH_KELURAHAN_SUCCESS';
export const FETCH_KELURAHAN_FAILURE = 'FETCH_KELURAHAN_FAILURE';
export const CLEAR_DATA_PHOTO = 'CLEAR_DATA_PHOTO';
export const CLEAR_DATA = 'CLEAR_DATA';