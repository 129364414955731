// reducers/botReducer.js
import { FETCH_BOTS_SUCCESS, FETCH_BOTS_FAILURE, FETCH_BOT_BY_ID_SUCCESS, FETCH_BOT_BY_ID_FAILURE, ADD_BOT, DELETE_BOT, UPDATE_BOT, CLEAR_DATA } from '../actions/actionTypes';

const initialState = {
  bots: [],
  bot: null,
  error: null,
};

const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOT:
      return {
        ...state,
        bot: action.payload,
      };
    case DELETE_BOT:
      return {
        ...state,
        bots: state.bots.filter((bot) => bot.id !== action.payload),
      };
    case UPDATE_BOT:
      return {
        ...state,
        bots: state.bots.map((bot) =>
          bot.id === action.payload.id ? action.payload : bot
        ),
      };
    case FETCH_BOTS_SUCCESS:
      return {
        ...state,
        bots: action.payload,
        error: null,
      };
    case FETCH_BOTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_BOT_BY_ID_SUCCESS:
      return {
        ...state,
        bot: action.payload,
        error: null,
      };
    case FETCH_BOT_BY_ID_FAILURE:
      return {
        ...state,
        bot: null,
        error: action.payload,
      };
    case CLEAR_DATA:
      return {
        ...state,
        bot: null,
        error: null,
      };
    default:
      return state;
  }
};

export default botReducer;
