import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "../router/ProtectedRoute";
const Main = lazy(() => import("../App"));
const Auth = lazy(() => import("../pages/auth"));
const NotFound = lazy(() => import("../pages/not-found"));

// USER
const CreateUser = lazy(() => import("../pages/user/create.user"));
const ListUser = lazy(() => import("../pages/user/list.user"));
const ProfileUser = lazy(() => import("../pages/user/profile.user"));

// ACCOUNT BOT
const ListBot = lazy(() => import("../pages/bot/list.bot"));

// DPO
const Biodata = lazy(() => import("../pages/dpo/biodata/filter.biodata"));
const Demographic = lazy(() =>
  import("../pages/dpo/demographic/filter.demographic")
);
const Keluarga = lazy(() => import("../pages/dpo/keluarga/filter.keluarga"));
const Konsolidasi = lazy(() =>
  import("../pages/dpo/konsolidasi/filter.konsolidasi")
);
const Handphone = lazy(() => import("../pages/dpo/handphone/filter.handphone"));
const Owner = lazy(() => import("../pages/dpo/owner/filter.owner"));
const Nopol = lazy(() => import("../pages/dpo/nopol/filter.nopol"));

const DetailBiodata = lazy(() => import("../pages/dpo/biodata/detail-biodata"));
const BiodataPDF = lazy(() => import("../pages/pdf/BiodataPdf"));

// TELEGRAM
const ZeusBotTelegram = lazy(() => import("../pages/telegram/zeus/create.bot"));
const ZeusGroupTelegram = lazy(() =>
  import("../pages/telegram/zeus/create.group")
);
const MisterBotTelegram = lazy(() =>
  import("../pages/telegram/mister/create.bot")
);
const MisterGroupTelegram = lazy(() =>
  import("../pages/telegram/mister/create.group")
);
const SatgasBotTelegram = lazy(() =>
  import("../pages/telegram/satgas/create.bot")
);
const SatgasGroupTelegram = lazy(() =>
  import("../pages/telegram/satgas/create.group")
);
const NeverGiveUpBotTelegram = lazy(() =>
  import("../pages/telegram/never/create.bot")
);
const NeverGiveUpGroupTelegram = lazy(() =>
  import("../pages/telegram/never/create.group")
);
const DoxxBotTelegram = lazy(() =>
  import("../pages/telegram/doxx/create.bot")
);
const DoxxGroupTelegram = lazy(() =>
  import("../pages/telegram/doxx/create.group")
);


const AppRouter = () => {
  const isAuthenticated = localStorage.getItem("token_shadow");
  return (
    <React.Fragment>
      <Router>
        <Suspense
          fallback={
            <div className="spin">
              <Spin
                style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                size="large"
              ></Spin>
            </div>
          }
        >
          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/not-found" />} />
            <Route
              path="/"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Main />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bot/list"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ListBot />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account/create"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <CreateUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account/list"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ListUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account/profile"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ProfileUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/biodata"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Biodata />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/demographic"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Demographic />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/keluarga"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Keluarga />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/konsolidasi"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Konsolidasi />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/handphone"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Handphone />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/owner"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Owner />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/nopol"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Nopol />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/detail-biodata"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <DetailBiodata />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dpo/pdf"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <BiodataPDF />
                </ProtectedRoute>
              }
            />

            <Route
              path="/zeus/bot"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ZeusBotTelegram />
                </ProtectedRoute>
              }
            />
            <Route
              path="/zeus/group"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ZeusGroupTelegram />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mister/bot"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <MisterBotTelegram />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mister/group"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <MisterGroupTelegram />
                </ProtectedRoute>
              }
            />
            <Route
              path="/satgas/bot"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <SatgasBotTelegram />
                </ProtectedRoute>
              }
            />
            <Route
              path="/satgas/group"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <SatgasGroupTelegram />
                </ProtectedRoute>
              }
            />
            <Route
              path="/never/bot"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <NeverGiveUpBotTelegram/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/never/group"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <NeverGiveUpGroupTelegram />
                </ProtectedRoute>
              }
            />
            <Route
              path="/never/bot"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <NeverGiveUpBotTelegram/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/doxx/group"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <DoxxGroupTelegram />
                </ProtectedRoute>
              }
            />
            <Route
              path="/doxx/bot"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <DoxxBotTelegram/>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </React.Fragment>
  );
};

export default AppRouter;
