import {
  FETCH_DPO_BIODATA_SUCCESS, FETCH_DPO_BIODATA_FAILURE, FETCH_DPO_NOPOL_SUCCESS, FETCH_DPO_NOPOL_FAILURE,
  FETCH_DPO_PHONE_FAILURE, FETCH_DPO_PHONE_SUCCESS, FETCH_DPO_PHONE_OWNER_FAILURE, FETCH_DPO_PHONE_OWNER_SUCCESS, FETCH_DPO_FAMILY_FAILURE, FETCH_DPO_FAMILY_SUCCESS,
  FETCH_DPO_DEMOGRAPHIC_FAILURE, FETCH_DPO_DEMOGRAPHIC_SUCCESS, FETCH_DPO_PHOTO_SUCCESS, FETCH_DPO_PHOTO_FAILURE, CLEAR_DATA_PHOTO, CLEAR_DATA, FETCH_DPO_KONSOLIDASI_SUCCESS, FETCH_DPO_KONSOLIDASI_FAILURE
} from '../actions/actionTypes';

const initialState = {
  dpo: [],
  demographic: [],
  nopol: [],
  phone: [],
  owner: [],
  family: [],
  konsolidasi: [],
  photo: [],
  error: null,
};

const dpoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DPO_BIODATA_SUCCESS:
      return {
        ...state,
        dpo: action.payload,
        error: null,
      };
    case FETCH_DPO_BIODATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_DPO_DEMOGRAPHIC_SUCCESS:
      return {
        ...state,
        demographic: action.payload,
        error: null,
      };
    case FETCH_DPO_DEMOGRAPHIC_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_DPO_NOPOL_SUCCESS:
      return {
        ...state,
        nopol: action.payload,
        error: null,
      };
    case FETCH_DPO_NOPOL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_DPO_PHONE_SUCCESS:
      return {
        ...state,
        phone: action.payload,
        error: null,
      };
    case FETCH_DPO_PHONE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_DPO_PHONE_OWNER_SUCCESS:
      return {
        ...state,
        owner: action.payload,
        error: null,
      };
    case FETCH_DPO_PHONE_OWNER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_DPO_FAMILY_SUCCESS:
      return {
        ...state,
        family: action.payload,
        error: null,
      };
    case FETCH_DPO_FAMILY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_DPO_KONSOLIDASI_SUCCESS:
      return {
        ...state,
        konsolidasi: action.payload,
        error: null,
      };
    case FETCH_DPO_KONSOLIDASI_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_DPO_PHOTO_SUCCESS:
      return {
        ...state,
        photo: action.payload,
        error: null,
      };
    case FETCH_DPO_PHOTO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_DATA_PHOTO:
      return {
        ...state,
        photo: [],
      };
    case CLEAR_DATA:
      return {
        ...state,
        family: [],
      };
    default:
      return state;
  }
};

export default dpoReducer;
