import { FETCH_BOT_TELEGRAM_SUCCESS, FETCH_BOT_TELEGRAM_FAILURE, FETCH_GROUP_TELEGRAM_FAILURE, FETCH_GROUP_TELEGRAM_SUCCESS, FETCH_CHAT_ID_TELEGRAM_SUCCESS, FETCH_CHAT_ID_TELEGRAM_FAILURE, ADD_BOT_TELEGRAM, ADD_GROUP_TELEGRAM, DELETE_BOT_TELEGRAM, DELETE_GROUP_TELEGRAM,CLEAR_DATA } from '../actions/actionTypes';

const initialState = {
    bots: [],
    groups: [],
    dialogs: [],
    error: null,
    msg: null
};

const telegramReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOT_TELEGRAM_SUCCESS:
            return {
                ...state,
                bots: action.payload,
                error: null,
            };
        case FETCH_BOT_TELEGRAM_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case FETCH_GROUP_TELEGRAM_SUCCESS:
            return {
                ...state,
                groups: action.payload,
                error: null,
            };
        case FETCH_GROUP_TELEGRAM_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case FETCH_CHAT_ID_TELEGRAM_SUCCESS:
            return {
                ...state,
                dialogs: action.payload,
                error: null,
            };
        case FETCH_CHAT_ID_TELEGRAM_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_BOT_TELEGRAM:
            return {
                ...state,
                msg: action.payload,
                error: null,
            };
        case ADD_GROUP_TELEGRAM:
            return {
                ...state,
                msg: action.payload,
                error: null,
            };
        case DELETE_BOT_TELEGRAM:
            return {
                ...state,
                bots: state.bots.filter((bot) => bot.bot_id !== action.payload),
            };
        case DELETE_GROUP_TELEGRAM:
            return {
                ...state,
                groups: state.groups.filter((group) => group.group_id !== action.payload),
            };

        case CLEAR_DATA:
            return {
                ...state,
                msg: null,
                error: null
            };
        default:
            return state;
    }
};

export default telegramReducer;
